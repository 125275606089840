import { gql } from '@apollo/client'

export const COSTPLANS_QUERY = gql`
  query COSTPLANS($personReferenceId: UUID!, $kind: CostPlanKind!) {
    costPlans(id: $personReferenceId, kind: $kind) {
      data {
        externalId
        referenceId
        clinicReferenceId
        clinicOfficialName
        practitionerReferenceId
        practitionerDisplayName
        type
        start
        status
        statusChangedAt
        totalCosts
        costsToBePaidByPatient
        numberOfInstallmentsInMonths
        isFinancingAvailable
      }
    }
  }
`
