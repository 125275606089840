import React, { createContext, useContext, useReducer } from 'react'

import { useTranslation } from 'next-i18next'

import { useLocalStorage, InsuranceType } from '@dentalux/common'
import {
  Form,
  ensureConditionalFieldValues,
  wishesForPage1,
  wishesForPage2,
  wishesForPage3,
  wishesForPage4,
} from '@dentalux/ui-library-core/cjs/custom/components/ana-toolbox'

import { Anamnesis } from 'src/@types/Anamnesis'
import { LocalStorage } from 'src/@types/LocalStorage'
import { Gender, Person } from 'src/@types/Person'
import { useAnamnesisSourceMutation } from 'src/hooks/api/useAnamnesisSourceMutation'
import { AnaMode } from 'src/hooks/useAnaNavigation'

export enum ActionTypes {
  setAnswers = 'setAnswers',
  setRenew = 'setRenew',
  setNew = 'setNew',
  setEdit = 'setEdit',
  setLoading = 'setLoading',
  setSourceReferenceId = 'setSourceReferenceId',
  reset = 'reset',
}

type NewPayload = {
  language: string
  clinicReferenceId: string
  data: {
    firstName: string
    lastName: string
    email: string
    phone: string
    birthday: string
  }
}

type RenewPayload = {
  language: string
  clinicReferenceId: string
  factoringAgreementMissing: boolean
  referenceId: string
  data: Anamnesis
}

type EditPayload = RenewPayload

type AnswersPayload = Partial<Form>

type SetAnswersAction = { type: ActionTypes.setAnswers; payload: AnswersPayload }

type SetRenewAction = {
  type: ActionTypes.setRenew
  payload: RenewPayload
}

type SetNewAction = {
  type: ActionTypes.setNew
  payload: NewPayload
}

type SetLoadingAction = {
  type: ActionTypes.setLoading
  payload: boolean
}

type SetSourceReferenceIdAction = {
  type: ActionTypes.setSourceReferenceId
  payload: string
}

type SetEditAction = {
  type: ActionTypes.setEdit
  payload: EditPayload
}

type ResetAction = { type: ActionTypes.reset }

type State = {
  answers: Partial<Form>
  clinicReferenceId: string
  factoringAgreementMissing: boolean
  referenceId: string
  sourceReferenceId?: string
  loading: boolean
}

type Dispatch = (
  action:
    | SetAnswersAction
    | ResetAction
    | SetRenewAction
    | SetNewAction
    | SetEditAction
    | SetSourceReferenceIdAction
    | SetLoadingAction
) => void

const AnaContext = createContext<State | undefined>(undefined)

const AnaDispatchContext = createContext<Dispatch | undefined>(undefined)

const renewAnswers = (language: string, data: Anamnesis) => {
  const {
    person,
    diseases,
    medications,
    heightOver2M,
    wishesAndExpectations,
    weightOver120Kg,
    hasAllergies,
    lifeStyle,
    insurance,
  } = data

  return {
    language,
    gender: person.gender,
    firstName: person.firstName || '',
    lastName: person.lastName || '',
    birthday: loadDate(person.birthday || ''),
    placeOfBirth: person.placeOfBirth || '',

    street: person.address.street,
    zipCode: person.address.zipCode,
    country: person.address.country,
    city: person.address.city,

    email: person.email || '',
    phone: person.phone || '',

    filedForDifferentPerson: false,
    visitReason: '',

    type: InsuranceType[insurance.type],
    dentalInsuranceCompany: insurance.dentalInsuranceCompany || '',
    dentalInsuranceCompanyId: insurance.dentalInsuranceCompanyId || '',
    insuranceCompany: insurance.company || '',
    insuranceCompanyId: insurance.companyId || '',
    hasCareLevel: null,
    careLevelLimitedInTime: null,
    hasFamilyInsurance: insurance.hasFamilyInsurance,
    livesOnDifferentAddress: insurance.livesOnDifferentAddress || false,
    hasCivilServiceAllowance: insurance.hasCivilServiceAllowance || false,
    hasDentalInsurance: insurance.hasDentalInsurance,

    holderFirstName: insurance.holderFirstName || '',
    holderLastName: insurance.holderLastName || '',
    holderBirthday: loadDate(insurance.holderBirthday || ''),
    holderStreet: insurance.holderStreet || '',
    holderZipcode: insurance.holderZipcode || '',
    holderCity: insurance.holderCity || '',

    isPregnant: person.gender !== Gender.Male ? null : false,
    occupation: lifeStyle.occupation || '',
    pregnantMonths: undefined,
    cigarettesPerDay: lifeStyle.cigarettesPerDay || undefined,
    isSmoker: lifeStyle.isSmoker || false,
    consumesAlcohol: lifeStyle.consumesAlcohol,
    consumesDrugs: lifeStyle.consumesDrugs,

    weightOver120Kg: weightOver120Kg || false,
    heightOver2M: heightOver2M || false,

    diseases: diseases || [],
    hasAllergies: hasAllergies || 'UNKNOWN',
    medications: medications || [],
    wishesAndExpectations: [...wishesForPage1, ...wishesForPage2, ...wishesForPage3, ...wishesForPage4].map((woe) =>
      woe.key === 'has_bridges' ? wishesAndExpectations.find((el) => el.key === 'has_bridges') || woe : woe
    ),
    allergyPass: false,
  }
}

const newAnswers = (language: string, person: Person) => {
  return {
    language,
    hasFamilyInsurance: false,
    hasDentalInsurance: false,
    consumesAlcohol: false,
    consumesDrugs: false,
    medications: [],
    hasCareLevel: null,
    careLevelLimitedInTime: null,
    isSmoker: false,
    allergyPass: false,
    hasCivilServiceAllowance: false,
    firstName: person.firstName,
    lastName: person.lastName,
    email: person.email,
    phone: person.phoneNumber,
    isPregnant: undefined,
    wishesAndExpectations: [...wishesForPage1, ...wishesForPage2, ...wishesForPage3, ...wishesForPage4],
    gender: person.gender,
    birthday: loadDate(person.birthday),
  }
}

const editAnswers = (language: string, data: Anamnesis) => {
  const { wishesAndExpectations, allergyPass, insurance } = data

  return {
    ...renewAnswers(language, data),
    language,
    wishesAndExpectations: [...wishesForPage1, ...wishesForPage2, ...wishesForPage3, ...wishesForPage4].map((woe) => {
      return wishesAndExpectations.find((el) => el.key === woe.key) || woe
    }),
    allergyPass,
    isPregnant: data.lifeStyle.isPregnant,
    hasCareLevel: insurance.hasCareLevel === null ? null : insurance.hasCareLevel ? 'true' : 'false',
    careLevelLimitedInTime:
      insurance.careLevelLimitedInTime === null ? null : insurance.careLevelLimitedInTime ? 'true' : 'false',
    filedForDifferentPerson: false,
    visitReason: data.visitReason || '',
  }
}

export const defaultAnaState: State = {
  referenceId: '',
  sourceReferenceId: '',
  clinicReferenceId: '',
  factoringAgreementMissing: false,
  answers: {},
  loading: false,
}

export const loadDate = (date: string) => date?.split('-').reverse().join('.')

const unauthorizedReducer =
  (persist) =>
  (
    state: State,
    action:
      | SetAnswersAction
      | SetSourceReferenceIdAction
      | SetNewAction
      | SetEditAction
      | ResetAction
      | SetLoadingAction
      | SetRenewAction
  ) => {
    const finalize = (nextState: State) => {
      persist(nextState)
      return nextState
    }

    switch (action.type) {
      case ActionTypes.setAnswers: {
        return finalize({ ...state, answers: ensureConditionalFieldValues({ ...state.answers, ...action.payload }) })
      }

      case ActionTypes.reset:
        return finalize(defaultAnaState)

      case ActionTypes.setSourceReferenceId: {
        return finalize({ ...state, sourceReferenceId: action.payload })
      }

      case ActionTypes.setLoading:
        return finalize({ ...state, loading: action.payload })

      case ActionTypes.setRenew: {
        const { data, language, clinicReferenceId, factoringAgreementMissing, referenceId } = action.payload

        return finalize({
          factoringAgreementMissing,
          referenceId,
          clinicReferenceId,
          loading: false,
          answers: renewAnswers(language, data),
        })
      }

      case ActionTypes.setNew: {
        const { language, clinicReferenceId, data } = action.payload

        return finalize({
          factoringAgreementMissing: true,
          referenceId: '',
          clinicReferenceId,
          loading: false,
          answers: { language, wishesAndExpectations: [], ...data },
        })
      }

      case ActionTypes.setEdit: {
        const { referenceId, clinicReferenceId, data, language, factoringAgreementMissing } = action.payload

        return finalize({
          factoringAgreementMissing,
          referenceId,
          clinicReferenceId,
          loading: false,
          answers: editAnswers(language, data),
        })
      }

      default:
        throw new Error(`Unhandled action`)
    }
  }

export const AnaProvider = ({ children }) => {
  const [value, setValue] = useLocalStorage(LocalStorage.Ana, defaultAnaState)

  const [state, dispatch] = useReducer(unauthorizedReducer(setValue), { ...value })

  return (
    <AnaContext.Provider value={state}>
      <AnaDispatchContext.Provider value={dispatch}>{children}</AnaDispatchContext.Provider>
    </AnaContext.Provider>
  )
}

export const useAnaContext = () => {
  const context = useContext(AnaContext)

  if (context === undefined) {
    throw new Error('useAnaContext must be used within a useAnaContext')
  }

  return context
}

export const useInit = ({ mode }: { mode: AnaMode }) => {
  const dispatch = useAnaDispatch()

  const { sourceReferenceId } = useAnaContext()

  const { mutateAsync } = useAnamnesisSourceMutation()

  const {
    i18n: { language },
  } = useTranslation()

  return {
    initNew: async ({ clinicReferenceId, person }: { clinicReferenceId: string; person: Person }) => {
      dispatch(
        setNew({
          clinicReferenceId,
          language,
          data: sourceReferenceId
            ? editAnswers(language, {
                ...(await mutateAsync({ anamnesisReferenceId: sourceReferenceId })),
                clinicReferenceId,
                referenceId: '',
              })
            : newAnswers(language, person),
        })
      )
    },
    initRenewOrEdit: async (ana: Anamnesis) => {
      const { factoringAgreementResult } = ana

      const { signed = false, valid = false, expired = false } = factoringAgreementResult ?? {}

      const payload = {
        language,
        clinicReferenceId: ana.clinicReferenceId,
        referenceId: String(ana.referenceId),
        data: sourceReferenceId
          ? {
              ...(await mutateAsync({ anamnesisReferenceId: sourceReferenceId })),
              clinicReferenceId: ana.clinicReferenceId,
              referenceId: ana.referenceId,
            }
          : ana,
        factoringAgreementMissing: expired || !signed || !valid,
      }

      dispatch((mode === AnaMode.RENEW ? setRenew : setEdit)(payload))
    },
  }
}

export const useAnaDispatch = () => {
  const context = useContext(AnaDispatchContext)

  if (context === undefined) {
    throw new Error('useAnaDispatch must be used within a useAnaContext')
  }

  return context
}

export const setAnswers = (payload: AnswersPayload): SetAnswersAction => ({
  type: ActionTypes.setAnswers,
  payload,
})

export const reset = (): ResetAction => ({
  type: ActionTypes.reset,
})

export const setRenew = (payload: RenewPayload): SetRenewAction => ({
  type: ActionTypes.setRenew,
  payload,
})

export const setNew = (payload: NewPayload): SetNewAction => ({
  type: ActionTypes.setNew,
  payload,
})

export const setEdit = (payload: EditPayload): SetEditAction => ({
  type: ActionTypes.setEdit,
  payload,
})

export const setLoading = (payload: boolean): SetLoadingAction => ({
  type: ActionTypes.setLoading,
  payload,
})

export const setSourceReferenceId = (payload: string): SetSourceReferenceIdAction => ({
  type: ActionTypes.setSourceReferenceId,
  payload,
})
