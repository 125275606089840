import { useRedirectUrlHandling } from 'src/hooks/useRedirectUrlHandling'
import { useResetAna } from 'src/hooks/useResetAna'
import { useSentryUserId } from 'src/hooks/useSentryUserId'

export const HooksRunner = () => {
  useRedirectUrlHandling({ shouldRead: false })
  useResetAna()
  useSentryUserId()

  return null
}
