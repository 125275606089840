import React from 'react'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { useLogout, LogoutKind, useSetCredentials } from '@dentalux/security'
import { Avatar } from '@dentalux/ui-library-core/cjs/components/avatar'

import { Box } from '@mui/material'
import Divider from '@mui/material/Divider'
import BaseListItemIcon from '@mui/material/ListItemIcon'
import BaseMenuItem from '@mui/material/MenuItem'

import { LocalStorage } from 'src/@types/LocalStorage'
import SettingsIcon from 'src/components/icons/Settings'
import SignOutIcon from 'src/components/icons/SignOut'
import usePerson from 'src/graphql/hooks/usePerson'
import { useProfileImage } from 'src/hooks/api/useProfileImage'
import { useInitials } from 'src/hooks/useInitials'

import { Photo } from '../icons/Photo'
import Menu from '../Menu'
import { reset as authroizedReset, useAuthorizedDispatch } from '../providers/AuthorizedProvider'
import { usePersonId } from '../providers/PersonIdProvider'
import { setDisableBiometricLogin, useUnauthorizedDispatch } from '../providers/UnauthorizedProvider'

import * as S from './SettingsMenu.styles'

const SettingsMenu = () => {
  const { t } = useTranslation()
  const logout = useLogout({ kind: LogoutKind.MANUAL })
  const authorizedDispatch = useAuthorizedDispatch()
  const unauthorizedDispatch = useUnauthorizedDispatch()
  const setCredentials = useSetCredentials()

  const router = useRouter()
  const personReferenceId = usePersonId()

  const { data: person } = usePerson({
    variables: { personReferenceId },
    skip: !personReferenceId,
  })

  const accountSettingsRoute = '/account-settings'

  const handleLogOut = () => {
    localStorage.removeItem(LocalStorage.RedirectUrl)
    logout().then(() => {
      setCredentials(null, 'local')
      unauthorizedDispatch(setDisableBiometricLogin())
      authorizedDispatch(authroizedReset())
    })
  }

  const handleDeleteAccount = () => router.push(accountSettingsRoute)

  const { src: profileImageSrc } = useProfileImage({})

  const initials = useInitials({ person })

  const avatarProps = profileImageSrc ? { src: profileImageSrc } : { children: initials }

  return (
    <Menu
      justifyContent="right"
      trigger={
        <S.Trigger>
          <Avatar size="m" {...avatarProps} />
        </S.Trigger>
      }
    >
      <S.About>
        <S.Link href={accountSettingsRoute} passHref>
          <S.Frame>
            <Avatar size="l" {...avatarProps} />
            <S.PhotoIcon>
              <Photo width={11} height={8} />
            </S.PhotoIcon>
          </S.Frame>
        </S.Link>
        <Box>
          <S.Name>{`${person?.firstName} ${person?.lastName}`}</S.Name>
        </Box>
      </S.About>

      <S.BaseMenuList>
        <BaseMenuItem onClick={handleDeleteAccount}>
          <BaseListItemIcon>
            <SettingsIcon />
          </BaseListItemIcon>

          <S.ListItemText primary={t('common.settings_menu.settings')} />
        </BaseMenuItem>

        <Divider />

        <BaseMenuItem onClick={handleLogOut}>
          <BaseListItemIcon>
            <SignOutIcon />
          </BaseListItemIcon>

          <S.ListItemText primary={t('common.settings_menu.logout')} />
        </BaseMenuItem>
      </S.BaseMenuList>
    </Menu>
  )
}

export default SettingsMenu
