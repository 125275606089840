import { QueryHookOptions, useQuery } from '@apollo/client'
import { CostPlan } from 'src/@types/CostPlan'
import { Nullable } from 'src/@types/Nullable'
import { usePersonId } from 'src/components/providers/PersonIdProvider'
import useGraphQLErrorBoundary from 'src/hooks/useGraphQLErrorBoundary'

import { COSTPLANS_QUERY } from '../queries/costPlans'
import { CostPlansVariables } from '../types/costPlansVariables'
import { PageInfo } from '../types/PageInfo'

type CostPlansResponse = { costPlans: Nullable<{ pageInfo: PageInfo; data: CostPlan[] }> }

export enum CostPlanKind {
  All = 'ALL',
  Accepted = 'ACCEPTED',
}

export const useCostPlans = ({
  options,
  throwOnError = true,
}: {
  options: QueryHookOptions<CostPlansResponse, CostPlansVariables>
  throwOnError?: boolean
}) => {
  const personReferenceId = usePersonId()

  const { data, ...rest } = useQuery<CostPlansResponse, CostPlansVariables>(COSTPLANS_QUERY, {
    ...options,
    variables: {
      personReferenceId,
      ...options.variables,
    } as CostPlansVariables,
    skip: !personReferenceId,
  })

  useGraphQLErrorBoundary(throwOnError ? rest.error : null)

  return { data: data?.costPlans, ...rest }
}
