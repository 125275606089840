import { usePersonId } from 'src/components/providers/PersonIdProvider'
import useClinicPractitioners, { ClinicPractitionerKind } from 'src/graphql/hooks/useClinicPractitioners'

export const usePractitionerEvaluation = ({ enabled = true }: { enabled?: boolean }) => {
  const personReferenceId = usePersonId()

  const { data: clinicPractitioners } = useClinicPractitioners({
    options: {
      variables: { kind: ClinicPractitionerKind.All },
      skip: !personReferenceId || !enabled,
      fetchPolicy: 'network-only',
    },
  })

  return {
    all: clinicPractitioners?.data?.flat() || [],
    favourite: clinicPractitioners?.data?.filter((cp) => cp.practitioners.find((p) => p.favorite))?.flat() || [],
  }
}
