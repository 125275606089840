import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'

import { setLoading, useAnaContext, useAnaDispatch } from 'src/components/providers/AnaProvider'
import { AnaSection } from 'src/screens/Anamnesis/Anamnesis/Anamnesis'

export const getIndices = ({ factoringAgreementMissing }: { factoringAgreementMissing: boolean }) => ({
  [AnaSection.PersonalInformation]: 0,
  [AnaSection.ContactDetails]: 0,
  [AnaSection.Insurance]: 0,
  [AnaSection.Miscellaneous]: 0,
  [AnaSection.Preconditions1]: 1,
  [AnaSection.Preconditions2]: 1,
  [AnaSection.Preconditions3]: 1,
  [AnaSection.AllergiesAndMedications]: 1,
  [AnaSection.Wishes1]: 2,
  [AnaSection.Wishes2]: 2,
  [AnaSection.Wishes3]: 2,
  [AnaSection.Wishes4]: 2,
  [AnaSection.Consents]: 2,
  ...(factoringAgreementMissing ? { [AnaSection.FactoringAgreement]: 3 } : {}),
  [AnaSection.Success]: factoringAgreementMissing ? 3 : 2,
})

export enum AnaMode {
  RENEW = 'renew',
  EDIT = 'edit',
  NEW = 'new',
}

export function useAnaNavigation({ mode }: { mode: AnaMode }) {
  const { asPath, push } = useRouter()

  const { factoringAgreementMissing } = useAnaContext()

  const dispatch = useAnaDispatch()

  const indices = getIndices({ factoringAgreementMissing })
  const steps = Object.keys(indices)

  const search = useSearchParams()
  const searchReferenceId = search.get('referenceId')

  const parts = (() => {
    const path = asPath.toLowerCase()
    const containedSeparator = `/${mode}/`
    const separator = `/${mode}`

    return path
      .replace('referenceid', searchReferenceId)
      .split(path.includes(containedSeparator) ? containedSeparator : separator)
  })()

  const base = parts[0]
  const step = parts[1].split('?')[0] || AnaSection.PersonalInformation

  const allDone = step === AnaSection.Success

  const nextStep = steps[steps.indexOf(step) + 1]

  const nextPage = nextStep ? `${base}/${mode}/${nextStep}` : '/'

  return {
    step,
    indices,
    allDone,
    goToNextStep: () => {
      dispatch(setLoading(true))
      push(nextPage)
    },
  }
}
