import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import { ClinicArea, ClinicRensponse } from 'src/@types/Clinic'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CLINIC_URL,
})

addAuthInterceptors(http)

export const getClinic = async (clinicReferenceId: string): Promise<ClinicRensponse> => {
  const { data } = await http.get(`/v1/external/clinics/${clinicReferenceId}`)

  return data
}

export const getClinicsAreas = async (): Promise<ClinicArea[]> => {
  const { data } = await http.get('/v1/external/clinics/areas')

  return data
}
