import { styled } from '@mui/material/styles'

export const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2),
  boxShadow: theme.shadows[3],
  background: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
