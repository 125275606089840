import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'
import { createTheme } from '@dentalux/ui-library-core/cjs/constants/create-theme'
import { MuiAlert } from '@dentalux/ui-library-core/cjs/themes/alert'
import { MuiButton } from '@dentalux/ui-library-core/cjs/themes/button'
import { MuiFormHelperText } from '@dentalux/ui-library-core/cjs/themes/form-helper-text'
import { shadows } from '@dentalux/ui-library-core/cjs/themes/shadows'
import { MuiTypography, typography } from '@dentalux/ui-library-core/cjs/themes/typography'

import { ThemeOptions } from '@mui/material/styles'

import '@dentalux/ui-library-core/cjs/typography.css'

import MuiAccordion from './overrides/MuiAccordion'
import MuiCssBaseline from './overrides/MuiCssBaseline'
import MuiDivider from './overrides/MuiDivider'
import MuiFilledInput from './overrides/MuiFilledInput'
import MuiInputBase from './overrides/MuiInputBase'
import MuiInputLabel from './overrides/MuiInputLabel'
import MuiLink from './overrides/MuiLink'
import MuiListItemIcon from './overrides/MuiListItemIcon'
import MuiMenu from './overrides/MuiMenu'
import MuiMenuItem from './overrides/MuiMenuItem'
import MuiPaper from './overrides/MuiPaper'
import MuiRating from './overrides/MuiRating'
import MuiTab from './overrides/MuiTab'
import MuiTabs from './overrides/MuiTabs'
import MuiTooltip from './overrides/MuiTooltip'

const theme = createTheme({
  shadows,
  typography,
  components: {
    MuiTypography,
    MuiPaper,
    MuiTab,
    MuiTooltip,
    MuiTabs,
    MuiAccordion,
    MuiMenuItem,
    MuiInputBase,
    MuiInputLabel,
    MuiFilledInput,
    MuiFormHelperText,
    MuiListItemIcon,
    MuiAlert,
    MuiLink,
    MuiRating,
    MuiMenu,
    MuiDivider,
    MuiButton,
    MuiCssBaseline,
  },
  palette: {
    mode: 'light',
    text: {
      primary: colors.grey[800],
    },
    background: {
      default: colors.aqua[10],
    },
    secondary: {
      main: colors.orange[400],
      ...colors.orange,
    },
    primary: {
      main: colors.aqua[800],
      ...colors.aqua,
    },
    grey: colors.grey,
    error: colors.error,
  },
} as ThemeOptions)

export default theme
